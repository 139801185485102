import type { EventData } from "@/types";

const usePromotionsSocket = () => {
	const { close } = useFunrizeModals();
	const { add, remove } = useSockets();
	const isGuest = useIsGuest();

	const { refresh: refreshAppInitData } = useAppInitData({ immediate: false });
	const { refresh: refreshHomeData } = useHomeData({ immediate: false });
	const { refresh: refreshPromotionsData } = usePromotionsPage({ immediate: false });
	const { refresh: refreshAmountPromotions } = useAsyncFetch({
		path: "/rest/page/promotions/amount/",
		method: "get",
		options: {
			immediate: false
		}
	});

	const updatePromotionsData = () => {
		refreshHomeData();
		if (!isGuest.value) {
			refreshPromotionsData();
			refreshAmountPromotions();
		}
	};

	onMounted(() => {
		add("promotionsPage", ({ action }: EventData) => {
			if (action === "update") {
				updatePromotionsData();
			}
		});

		add("partnerReferral", ({ action }: EventData) => {
			if (action === "qualified") {
				close("LazyOModalVerificationBonus");
				updatePromotionsData();
				refreshAppInitData();
				window?.$cash?.$store?.dispatch?.("cash/getAppCash");
				localStorage.removeItem("isFraudDetectorRunning");
			}
		});
	});

	onBeforeUnmount(() => {
		remove("promotionsPage");
	});
};

export default usePromotionsSocket;
